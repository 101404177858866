<template>
  <div>
    <b-modal
        id="modal-update-agent-wallet"
        cancel-variant="outline-secondary"
        :ok-title="$t('Confirm')"
        :cancel-title="$t('Close')"
        ok-variant="primary"
        centered
        modal-class="modal-primary"
        :title="$t('Update agent wallet')"
        @ok="confirm"
        @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="updateAgentWalletModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="name">
            <label class="mb-1">{{ $t("Name") }}</label>
            <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
            >
              <b-form-input
                  id="name"
                  v-model="agentData.name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="wallet_name">
            <label class="mb-1">{{ $t("Wallet Name") }}</label>
            <validation-provider
                #default="{ errors }"
                name="wallet_name"
                rules="required"
            >
              <b-form-input
                  id="wallet_name"
                  v-model="agentData.wallet_name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="" label-for="startMaintenance">
            <label class="mb-1">Start Maintenance</label>
            <flat-pickr
                id="startMaintenance"
                :config="formatFromDate"
                v-model="agentData.start_maintenance"
                class="form-control"
                placeholder="YYYY-MM-DD hh:mm:ss"
            />
          </b-form-group>

          <b-form-group label="" label-for="startMaintenance">
            <label class="mb-1">End Maintenance</label>
            <flat-pickr
                id="endMaintenance"
                :config="formatFromDate"
                v-model="agentData.end_maintenance"
                class="form-control"
                placeholder="YYYY-MM-DD hh:mm:ss"
            />
          </b-form-group>

          <b-form-group label-for="status">
            <label class="mb-1 d-block">Status</label>
            <b-form-radio
                v-model="agentData.status"
                name="status"
                value="1"
                inline
            >
              Active
            </b-form-radio>
            <b-form-radio
                v-model="agentData.status"
                name="status"
                value="2"
                inline
            >
              Inactive
            </b-form-radio>
          </b-form-group>

          <b-col cols="12 px-0">
            <b-form-group label-for="logo">
              <label for=""
              ><span class="text-danger">*</span> {{ $t("Image") }}
                <span class="text-secondary">(Không > 10Mb)</span></label
              >
              <validation-provider
                  #default="{ errors }"
                  name="logo"
                  :rules="isHasLogo ? 'required|size:10240' : ''"
              >
                <b-form-file
                    v-model="agentData.logo"
                    multiple
                    input-id="logo"
                    accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                />
                <a :href="agentData.logo">
                  <img
                      :src="agentData.logo"
                      fluid
                      style="height: 80px; width: auto"
                      class="mr-2 rounded"
                      alt=""
                  />
                </a>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
                v-show="
                agentData.logo.length !== 0 && typeof agentData.logo == 'object'
              "
                variant="outline-danger"
                class="btn-sm btn-icon"
                @click="agentData.logo = []"
            >
              <feather-icon icon="XIcon"/>
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormRadio,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import {ref, onUnmounted} from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import baseUrl from "@/libs/base-url";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormRadio,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      // isHasLogo: false,
      agentData: {
        id: "",
        name: "",
        status: "",
        start_maintenance: "",
        wallet_name: "",
        end_maintenance: "",
        // start_end_maintenance: "",
        logo: [],
      },
    };
  },
  props: {
    agentWalletDetail: {
      type: Object,
      default: null,
    },
  },
  watch: {
    agentWalletDetail: {
      handler(newVal) {
        if (newVal) {
          this.agentData = {
            id: newVal.id,
            name: newVal.name,
            status: newVal.status,
            start_maintenance: newVal.start_maintenance,
            end_maintenance: newVal.end_maintenance,
            wallet_name: newVal?.balance_type.wallet_name,
            // start_end_maintenance: `${newVal.start_maintenance} to ${newVal.end_maintenance}`,
            logo: this.showAttachments(newVal.logo),
          };
        }
      },
      deep: true,
    },
    // agentData: {
    //   handler(newVal) {
    //     if (newVal.start_end_maintenance) {
    //       this.agentData.start_maintenance =
    //           newVal.start_end_maintenance.split(" to ")["0"];
    //       this.agentData.end_maintenance =
    //           newVal.start_end_maintenance.split(" to ")["1"];
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    isHasLogo() {
      if (this.agentData.logo.length > 0 && this.agentData.logo == "string") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    showAttachments(image) {
      if (image) {
        return baseUrl + image;
      }
    },
    validationForm() {
      this.$refs.updateAgentWalletModal.validate().then((success) => {
        if (success) {
          this.updateAgentWallet(this.agentData);
        }
      });
    },
    async updateAgentWallet(agentData) {
      console.log('agentData', agentData)
      await this.$store
          .dispatch("agent/updateAgentWallet", agentData)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code == "00") {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || "success",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$emit("refetch-data");
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$bvModal.hide("modal-update-agent-wallet");
                    this.resetModal();
                  }, "500");
                });
                this.$emit("refetch-data");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            }
          });
    },
    resetModal() {
      this.agentData = {
        id: "",
        name: "",
        status: "",
        start_maintenance: "",
        wallet_name: "",
        end_maintenance: "",
        // start_end_maintenance: "",
        logo: [],
      };
      this.$emit("update:agentWalletDetail", null);
    },
    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const formatFromDate = {
      enableTime: true,
      dateFormat: "Y-m-d H:i:S",
    };
    return {formatFromDate};
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>